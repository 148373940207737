.nav {
    position: fixed;
    top: 0; /* Aligns it to the top of the viewport */
    right: 0; /* Keeps it on the right side */
    padding-right: 1rem; /* Add space between nav and right edge */
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    gap: 1.5625rem;
    justify-content: center;
    align-items: center;
    z-index: 10;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
}
.nav-about {
    transform: translateX(-1.56rem);
}

.nav-skills {
    transform: translateX(-6.25rem);
}

.nav-projects {
    transform: translateX(-10.94rem);
}

.nav-contact {
    transform: translateX(-15.62rem);
}

a {
    width: 3.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.page-title {
    font-size: 0.875rem;
    color: white;
    cursor: default;
}

.nav-link {
    opacity: 0.7;
}

.nav-link:hover {
    opacity: 1;
}

.nav-link.current {
    opacity: 1;
}

@media (max-width: 1100px) {
    .nav-about,
    .nav-skills,
    .nav-projects,
    .nav-contact
    {
        transform: translateX(0);
    }
    .nav {
        top: 0;
        right: 0;
        transform: none; /* Ensure no unwanted shifts */
        height: 100vh; /* Maintain full height */
    }
    .nav-link {
        width: 2rem;
    }
    .nav-link img {
        cursor: pointer;
        width: 2rem;
    }
    .page-title {
        display: none;
    }
}
