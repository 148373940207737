#playerStats {
    width: 115rem;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 3rem;
    left: 3rem;
}

h1 {
    margin: 0;
    font-size: 1.5rem;
}

h2 {
    margin: 0;
    font-size: 1rem;
}

#playerStats__lines {
    display: flex;
    align-items: center;
}

#playerStats__lines__thick {
    display: block;
    background-color: white;
    height: 0.2rem;
    width:8.75rem;
}

#playerStats__lines__thin {
    display: block;
    background-color: lightgray;
    height: 0.1rem;
    width: 6.2rem;
}

@media (max-width: 1100px) {
    #playerStats {
        left: 50%;
        transform: translateX(-50%);
        width: 8rem;
        height: 2.5rem;
        top: 2rem;
    }
    h1 {
        font-size: .8rem
    }
    h2,
    h3,
    p {
        font-size: 0.7rem;
    }
}