.menu {
    width: 19rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 60%;
    transform: translateY(-60%);
    left: 10vw;
}

.item {
    height: 3.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.title {
    font-size: 1rem;
    padding-right: 2.5rem;
}

.item.active .title {
    font-size: 1.5rem;
}

.sub-container {
    width: 18.5rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 32%;
    right: 10vw;
    cursor: pointer;
}

.links-container {
    display: flex;
    flex-direction: column; /* Stacks the icon row and additional link vertically */
    align-items: center; /* Centers everything horizontally */
    gap: 1rem; /* Adds space between rows */
}

.icon-links {
    display: flex;
    gap: 10px; /* Space between icons */
}

.stanford-article-link img {
    width: 175px; /* Adjust size as desired */
    height: 175px;
    object-fit: contain; /* Ensures aspect ratio is maintained */
}


.school-logo {
    width: 65px; /* Adjust size as needed */
    height: 50px;
    margin-left: 10px;
    vertical-align: middle;
}

.icon-links img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.active-subheading {
    cursor: default;
}

.item.active:nth-child(1) {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #6a0dad 70%);
    border-radius: var(--radius-right);
}

.item.active:nth-child(2) {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #1e90ff 70%); /* blue */
    border-radius: var(--radius-right);
}

.item.active:nth-child(3) {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #00ffff 70%); /* cyan */
    border-radius: var(--radius-right);
}

.sub-container-1.active-subheading h3,
.sub-container-2.active-subheading h3,
.sub-container-3.active-subheading h3 {
    border-radius: var(--radius-left);
}

.sub-container-1.active-subheading h3 {
    background: linear-gradient(90deg, #6a0dad, rgba(255, 255, 255, 0) 100%); /* purple gradient */
    border-radius: 10px; /* Add border-radius if needed */
}

.sub-container-2.active-subheading h3 {
    background: linear-gradient(90deg, #1e90ff, rgba(255, 255, 255, 0) 100%); /* blue gradient */
    border-radius: 10px;
}

.sub-container-3.active-subheading h3 {
    background: linear-gradient(90deg, #00ffff, rgba(255, 255, 255, 0) 100%); /* cyan gradient */
    border-radius: 10px;
}

.item.active {
    position: relative;
    cursor: default;
}

.p-container {
    display: none;
}

.active-subheading .p-container {
    display: block;
    font-size: 1rem;
}

.icon-title-container {
    display: flex;
    gap: 1.2rem;
    margin-bottom: 0.5rem;
    cursor: default;
}

.icon {
    width: 3rem;
    height: 3rem;
}

.sub-container-1 h3,
.sub-container-2 h3,
.sub-container-3 h3 {
    position: relative;
    margin: 0.2rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
}

@media (max-width: 700px) {
    .sub-container {
        right: 1rem;
    }
    .item.active .title {
        font-size: 1rem;
    }
    .item {
        height: 2rem;
    }
}

@media (max-width: 700px) {
    .sub-container {
        width: 15rem;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
    .title {
        font-size: 0.8rem;
        padding-right: 2.5rem;
    }
    .active-subheading .p-container {
        font-size: 0.8rem;
    }
    
    .icon-title-container, .icon {
        display: none;
    }

    h1 {
        font-size: 0.8rem;
    }
    h2,
    h3,
    p {
        font-size: 0.8rem;
    } 
}

